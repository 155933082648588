/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "1dbb151e36454648945b8e5fe0433537",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "1dbb151e36454648945b8e5fe0433537",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://ujmcu5u3e5bjnosxr5aianu4oe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-keu5rod7wvex5lms2b45uk7teq",
    "aws_cognito_identity_pool_id": "us-east-1:898d2822-419c-4119-b5be-5051995aa013",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jRoCtHNOl",
    "aws_user_pools_web_client_id": "22ce68jhhljs1hbe0o1qeod2s",
    "oauth": {
        "domain": "propswapcc73e73d-cc73e73d-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/oauth/",
        "redirectSignOut": "http://localhost:19006/oauth/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "propswap2adc7fd0109a445ebddbc6784526179d-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
